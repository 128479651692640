import * as React from "react"
import { Helmet } from "react-helmet"

import Header from "../components/header"
import Theme from "../components/theme"
import Footer from "../components/footer";

const IndexPage = () => {
  return (
    <Theme>
      <Helmet>
        <title>Angela Hollstein - Coaching und Supervision</title>
      </Helmet>
      {/* Hero */}
      <div className="w-full min-h-screen p-16">

        <Header smallText />

        <h2 className="text-5xl text-gray-900 leading-tight px-6 font-bold mt-6">Impressum</h2>

        <div className="mt-6 md:mt-0">
          <p id="info-text">
          </p>
          <h3>Angaben gemäß § 5 TMG:</h3><br />
          <p>Angela Hollstein<br /> Spargelweg 19<br /> 79112 Freiburg
          </p><br /><br />
          <h3>Kontakt:</h3><br /> Telefon:
          <br /> 07664/4033617
          <br /><br /> E-Mail: impressum@angelahollstein.de
          <br />
          <p>&nbsp;</p>
          <p>Quelle: <em><a href="https://www.e-recht24.de">eRecht24</a></em></p><br />
          <h3>Haftungsausschluss (Disclaimer)</h3>
          <p><strong>Haftung für Inhalte</strong></p>
          <p>Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen
            Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet,
            übermittelte oder gespeicherte
            fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
            hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen
            Gesetzen bleiben
            hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
            Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte
            umgehend entfernen.</p>
          <p><strong>Haftung für Links</strong></p>
          <p>Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb
            können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist
            stets der
            jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
            Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
            erkennbar.
            Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
            Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend
            entfernen.</p>
          <p><strong>Urheberrecht</strong></p>
          <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
            Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen
            des Urheberrechtes
            bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
            sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom
            Betreiber
            erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
            gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
            entsprechenden
            Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p>
          <p>&nbsp;</p>
          <br />
        </div>

      </div>

      <Footer />
    </Theme>
  )
}

export default IndexPage
